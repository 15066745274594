import { useEffect } from "react";
import { find, get, pick } from "lodash";
import {
  differenceInHours,
  formatDistanceToNowStrict,
  getDate,
  getMonth,
  getYear,
  isToday,
  isValid,
  parse,
} from "date-fns";
import { bn, enUS } from "date-fns/locale";
import { format, utcToZonedTime } from "date-fns-tz";
import { parseUrl } from "query-string";
import Cookies from "universal-cookie";

import { BN_APPLE_PLAYSTORE, BN_GOOGLE_PLAYSTORE, ENG_APPLE_PLAYSTORE, ENG_GOOGLE_PLAYSTORE } from "./constant";
import { SOCIAL_TAG, STATIC_TAGS } from "../../../server/helpers/constants";
import { staticTexts } from "./Translate";

export const linkTarget = (hostUrl, url) => {
  return url.includes("ampstories/") || (validURL(url) && !String(url).match(hostUrl)) ? "_blank" : "_self";
};

export const getStorage = (key) => {
  let data = localStorage?.getItem(key);
  if (!data) return;
  try {
    data = JSON.parse(data);
  } catch (err) {
    console.log(err);
  }
  return data;
};

export const setStorage = (key, value) => {
  localStorage?.setItem(key, JSON.stringify(value));
};

export const mobileShare = (url) => {
  if (window && window.navigator && window.navigator.share) {
    window.navigator
      .share({
        url: decodeURI(url),
      })
      .catch(console.error);
  }
};
/*eslint-disable */
export const mobileAndTabCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(window.navigator.userAgent || window.navigator.vendor || window.opera);
  return check;
};
/* eslint-enable */

export const getJsonToCsv = (text, quoteChar = '"', delimiter = ",") => {
  const rows = text.split("\n");
  const headers = rows[0].split(",").map((item) => String(item).toLowerCase().trim());

  const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, "gs");

  const match = (line) => [...line.matchAll(regex)].map((m) => m[2]).slice(0, -1);

  let lines = text.split("\n");
  const heads = headers ?? match(lines.shift());
  lines = lines.slice(1);

  return lines.map((line) => {
    return match(line).reduce((acc, cur, i) => {
      // replace blank matches with `null`
      const val = cur.length <= 0 ? null : Number(cur) || cur;
      const key = heads[i] ?? `{i}`;
      return { ...acc, [key]: val };
    }, {});
  });
};

export const removeHtml = (html = "") => {
  if (!html) return "";
  const regex = /(<([^>]+)>)/gi;
  return html.replace(regex, "").trim();
};

export const getSlug = (collection, sections = [], domainSlug, navigateTo = "") => {
  if (navigateTo.trim() === "#") return "#";

  if (navigateTo && validURL(navigateTo)) {
    return navigateTo;
  }

  if (navigateTo && !validURL(navigateTo)) {
    return `/${navigateTo}`;
  }

  let slug = "#";

  if (collection && collection.slug) {
    const metadata = Object.keys(get(collection, ["metadata"], {}));
    const isSection = metadata.indexOf("section") !== -1;

    if (sections.length > 0 && isSection) {
      const sectionId = collection.metadata.section[0].id;
      const section = sections.find(({ id }) => id === sectionId);

      if (section && section["parent-id"] && !domainSlug) {
        const parentSection = sections.find(({ id }) => id === section["parent-id"]);

        if (parentSection && !domainSlug) {
          slug = `/${parentSection.slug}/${section.slug}`;
        }
      } else if ((section && !section["parent-id"]) || (section && domainSlug)) {
        slug = `/${section.slug}`;
      }
    } else {
      slug = `/collection/${collection.slug}`;
    }
  }

  return slug;
};

function getParameter(url, name = "") {
  const match = RegExp("[?&]" + name + "=([^&]*)").exec(url);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

export const bannerHeight = (collection = {}) => {
  const { desktop_banner: desktopBanner } = collection["associated-metadata"] || {};

  const divided = (getParameter(desktopBanner, "h") / getParameter(desktopBanner, "w") || 0) * 100;

  const mobileCoverImage = collection?.metadata?.["cover-image"] || {};

  const { metadata: coverMetaData = mobileCoverImage?.["cover-image-metadata"] || {} } = mobileCoverImage;

  const bannerCss = {
    "--mobile-padding-top": coverMetaData ? (coverMetaData.height / coverMetaData.width) * 100 + "%" : undefined,
    "--desktop-padding-top": divided ? divided + "%" : undefined,
  };

  return bannerCss;
};

export const componentProps = (collection) => {
  if (!collection) return {};
  const { "associated-metadata": associatedMetaData = {}, items } = collection;
  const {
    show_collection_name_website: showCollectionName,
    navigate_to: navigateTo = "",
    navigate_to_title: navigateToTitle = "",
    enable_auto_play: autoPlay,
    layout: layoutName,
    ad_slot: adSlot,
    ad_slot_1: adSlot1,
    ad_slot_2: adSlot2,
    widget_url: widgetUrl,
    widget_height: widgetHeight,
    height_for_mobile: widgetMobileHeight,
    height_for_desktop: widgetDesktopHeight,
    hide_last_story: hideLastStory = false,
    banner_img: bannerImg,
    banner_click: bannerClick,
    number_of_collections_to_show: numberOfCollectionsToShow = 0,
    number_of_stories_inside_collection_to_show: storiesToLoad,
    most_commented_title: mostCommentedTitle,
    most_commented_position: mostCommentedPosition,
    number_of_stories_skip_to_show: numberOfStoriesSkipToShow,
    slider_type_dots: showDots,
    number_of_stories_to_show: numberOfStoriesShow,
    collection_of_collections: collectionOfCollectionsType = false,
    show_section_tag: showSectionTag,
    slide_num_open: slideNumOpen = -1,
    hide_bottom_space: hideBottomSpace = false,
    desktop_banner: desktopBanner = "",
    first_column_theme: firstColumnTheme,
    second_column_theme: secondColumnTheme,
    theme,
    hide_sub_headline: hideSubHeadline,
  } = associatedMetaData;

  return {
    hideSubHeadline,
    showCollectionName,
    navigateTo,
    navigateToTitle,
    autoPlay,
    adSlot,
    adSlot1,
    adSlot2,
    widgetUrl,
    widgetHeight,
    hideLastStory,
    bannerImg,
    bannerClick,
    numberOfCollectionsToShow,
    storiesToLoad,
    mostCommentedTitle,
    mostCommentedPosition,
    items,
    numberOfStoriesSkipToShow,
    showDots,
    numberOfStoriesShow,
    collectionOfCollectionsType,
    showSectionTag,
    layoutName,
    widgetMobileHeight,
    widgetDesktopHeight,
    slideNumOpen,
    hideBottomSpace,
    desktopBanner,
    firstColumnTheme,
    secondColumnTheme,
    theme,
  };
};

export const getCollectionName = (collection, navigateToTitle) => {
  if (navigateToTitle) {
    return navigateToTitle;
  }
  return get(collection, ["name"], "");
};

export const getStoryData = (story) => {
  if (!story) return {};

  const { headline = story.headline, "hero-image": alternativeHeroImage } = story.alternative?.home?.default || {};

  const {
    "hero-image-caption": imageCaption = headline,
    "hero-image-metadata": imageMetadata,
    "hero-image-attribution": imageAttribution,
    "hero-image-s3-key": imageS3Key,
  } = alternativeHeroImage || story;

  return { headline, imageCaption, imageMetadata, imageAttribution, imageS3Key };
};

/**
 * Validate the Email
 */
export function isValidEmail(email) {
  // prettier-ignore
  const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (!re.test(email)) return false;
  if (email.length > 150 || email.length < 6) return false;
  return true;
}

/**
 * Given english number returns a bangla number
 */

const engToBnNumber = (char) => {
  const numbersObj = {
    0: "০",
    1: "১",
    2: "২",
    3: "৩",
    4: "৪",
    5: "৫",
    6: "৬",
    7: "৭",
    8: "৮",
    9: "৯",
    ".": ".",
    "-": "-",
  };
  return numbersObj[char];
};

export function toBanglaString(value) {
  return String(value)
    .toString()
    .split("")
    .map((num) => engToBnNumber(num) || num)
    .join("");
}

export const toBanglaNum = (value) => {
  if (isNaN(parseFloat(value)) || isNaN(value - 0)) {
    return "Invalid input type";
  }

  let str = "";

  return (
    value
      .toString()
      .split("")
      .forEach((num) => (str += engToBnNumber(num))),
    str
  );
};

export const toBanglaMonth = (value) => {
  const monthsObj = {
    1: "জানুয়ারি",
    2: "ফেব্রুয়ারি",
    3: "মার্চ",
    4: "এপ্রিল",
    5: "মে",
    6: "জুন",
    7: "জুলাই",
    8: "আগস্ট",
    9: "সেপ্টেম্বর",
    10: "অক্টোবর",
    11: "নভেম্বর",
    12: "ডিসেম্বর",
  };

  return monthsObj[value];
};

export const toEngMonth = (value) => {
  const monthsObj = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  return monthsObj[value];
};

export const getBanglaWeekName = (name) => {
  const weekObj = {
    Sunday: "রোববার",
    Monday: "সোমবার",
    Tuesday: "মঙ্গলবার",
    Wednesday: "বুধবার",
    Thursday: "বৃহস্পতিবার",
    Friday: "শুক্রবার",
    Saturday: "শনিবার",
  };
  return weekObj[name];
};

export const getBanglaWeekNameShort = (name) => {
  const weekObj = {
    Sun: "রোববার",
    Mon: "সোমবার",
    Tue: "মঙ্গলবার",
    Wed: "বুধবার",
    Thu: "বৃহস্পতিবার",
    Fri: "শুক্রবার",
    Sat: "শনিবার",
  };
  return weekObj[name];
};

export const getEnglishWeekName = (name) => {
  const weekObj = {
    Sun: "Sunday",
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
  };
  return weekObj[name];
};

/**
 * Get the date for the header in Bangla
 */

export const getDateFromUtils = (lang) => {
  const currentDate = new Date();
  const dateArray = new Date().toDateString().split(" ");
  const month = currentDate.getMonth() + 1;

  if (lang === "bn") {
    // prettier-ignore
    return `${getBanglaWeekNameShort(dateArray[0])}, ${toBanglaNum(dateArray[2])} ${toBanglaMonth(month)} ${toBanglaNum(
      dateArray[3]
    )}`;
  } else {
    return `${getEnglishWeekName(dateArray[0])}, ${dateArray[2]} ${toEngMonth(month)} ${dateArray[3]}`;
  }
};

export const pushToUrlParams = (qs) => {
  if (window.history.pushState) {
    const newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      qs +
      window.location.hash;
    window.history.replaceState({ path: newurl }, "", newurl);
  } else {
    window.location.search = qs;
  }
};

export const getSlotId = (slot = "", slug = "", index, scrollIndex) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /[&\/\\#,+()$~%.'":*?<>{}]/gi;
  slot = String(slot || "").replace(/\?.*$/, "");
  slug = String(slug || "").replace(/\?.*$/, "");
  index = String(index || "0").replace(/\?.*$/, "");
  scrollIndex = String(scrollIndex || "0").replace(/\?.*$/, "");
  return `${slot}-${slug}-${index}-${scrollIndex}`.replace(regex, "-");
};

export const getStoryDate = (publishedTime, lang, pageType = "", storyType = "") => {
  const hourDifference = differenceInHours(new Date(), new Date(publishedTime), { roundingMethod: "floor" });

  const localLang = lang === "bn" ? bn : enUS;
  const storyPage = String(pageType).includes("story-page");
  const m = lang === "bn" ? "M" : "";

  if (hourDifference < 23 && !storyPage) {
    return formatDistanceToNowStrict(new Date(publishedTime), {
      addSuffix: true,
      locale: localLang,
      roundingMethod: "floor",
    })
      .replace(/প্রায়|about/gi, "")
      .replace(/less than a/gi, 1)
      .replace(/ঘন্টা/g, "ঘণ্টা")
      .trim();
  } else {
    let pattern = `dd MMM${m} yyy`;

    if (storyPage) {
      pattern = String(storyType).includes("live-blog")
        ? `HH:mm ${isToday(new Date(publishedTime)) ? "" : `, MMM${m} dd`}`
        : `dd MMM${m} yyy, HH:mm`;
    }

    return format(publishedTime, pattern, { addSuffix: true, locale: localLang })
      .split("")
      .map((char) => (lang === "bn" && engToBnNumber(char)) || char)
      .join("")
      .replace(":", ": ");
  }
};

export const getDomainSectionInfo = (domainSlug, sections) => {
  return sections.find((item) => item["domain-slug"] === domainSlug && item["parent-id"] === null);
};

const sectionObjects = ({ parentSectionObj = {}, currentSectionObj = {}, childSections = [] }) => {
  const { "display-name": parentDisplayName, "section-url": parentSectionUrl } = parentSectionObj;

  const {
    "display-name": currentDisplayName,
    "section-url": currentSectionUrl,
    "parent-id": currentSectionParentId,
  } = currentSectionObj;
  const requiredObj = {};

  requiredObj.parentSection = parentDisplayName || null;
  requiredObj.parentSectionUrl = parentSectionUrl || null;
  requiredObj.currentSection = currentDisplayName || null;
  requiredObj.parentSectionId = currentSectionParentId || null;
  requiredObj.currentSectionUrl = currentSectionUrl || null;
  requiredObj.childSections = childSections;

  return requiredObj;
};

export const getSectionsDataForHeader = (sections, domainSlug, currentSection = {}, domainSectionObj) => {
  const currentSectionObj = sections.find((item) => item.id === currentSection[0].id);
  if (currentSectionObj["parent-id"]) {
    const parentSectionObj = sections.find((item) => item.id === currentSectionObj["parent-id"]);
    const siblingSections = sections.filter((item) => item["parent-id"] === parentSectionObj.id);

    let childSections = [];

    if (!domainSlug || domainSectionObj.id !== currentSectionObj["parent-id"]) {
      childSections = siblingSections;
    } else {
      childSections = sections.filter((item) => item["parent-id"] === currentSectionObj.id);
    }

    return sectionObjects({ parentSectionObj, currentSectionObj, childSections });
  } else {
    const childSections = sections.filter((item) => item["parent-id"] === currentSectionObj.id);
    return sectionObjects({ currentSectionObj, childSections });
  }
};

export function collectionMenus(menus, sectionSlug) {
  menus = menus.map(({ children }) => children).flat();
  return menus.filter(({ "section-slug": slug, children }) => slug === sectionSlug && children.length > 0)?.[0];
}

export const showSectionWithMenuItems = (sectionDataForHeader) => {
  const currentSectionData =
    sectionDataForHeader &&
    sectionDataForHeader.childSections.find((children) => children.name === sectionDataForHeader.currentSection);
  const colorCode = get(currentSectionData, ["data", "color"], "#FFFFFF");
  const hideColorCode = "#000001";
  return colorCode !== hideColorCode;
};

export const getBanglaDate = (value) => {
  const monthname = toBanglaMonth(getMonth(value) + 1);
  const date = toBanglaNum(getDate(value));
  const year = toBanglaNum(getYear(value));

  return `${date} ${monthname} ${year}`;
};

export const getEngDate = (value, type = "default") => {
  let monthName = getMonth(value) + 1;

  if (type === "monthname") {
    monthName = toEngMonth(monthName);
  }

  const date = getDate(value);
  const year = getYear(value);

  return `${date} ${monthName} ${year}`;
};

export const useOuterClickNotifier = (onOuterClick, innerRef) => {
  useEffect(() => {
    if (innerRef.current) {
      document.addEventListener("click", handleClick);
    }

    return () => document.removeEventListener("click", handleClick);

    function handleClick(e) {
      innerRef.current && !innerRef.current.contains(e.target) && onOuterClick(e);
    }
  }, [onOuterClick, innerRef]);
};

export const getKeyHighlights = (cards) => {
  return cards && cards.length > 0
    ? cards
        .filter((card) => get(card, ["metadata", "attributes", "key-event"], false))
        .map((card) => ({
          title: card["story-elements"].find((element) => element.type === "title").text,
          card,
        }))
    : null;
};

export const getAuthorName = (story = {}) => {
  const authorDisplayName = get(story, ["metadata", "story-attributes", "author-display-name", "0"], null);
  const authorObj = get(story, ["authors", "0"], null);
  const authorName = authorObj?.name || story["author-name"];
  return authorDisplayName || authorName;
};

export const getExcerpt = (story, maxLength = 25) => {
  const excerpt = get(story, ["metadata", "excerpt"], get(story, ["metadata", "summary"]));

  if (!excerpt) return "";

  const split = excerpt.split(" ");
  const length = split.length;
  if (length > maxLength) {
    return split.splice(0, maxLength).join(" ");
  }

  return excerpt;
};

export const getHeadline = (story = {}) => {
  return story.alternative?.home?.default?.headline || story.headline || "";
};

// Validation rules for the password
export const validatePassword = (str, type = "") => {
  if (!str) {
    return type === "confirm-password" ? "Please confirm your password" : "Please enter password";
  } else if (str.length < 8) {
    return "Password too short";
  } else if (str.search(/\d/) === -1 || str.search(/[a-zA-Z]/) === -1) {
    return (
      `Password you have entered is not strong enough. Please ensure your password uses combination of` +
      ` letters & numbers and is minimum 8 charcaters long`
    );
  }

  return null; // Indicates password is valid
};

export function validURL(str = "") {
  // prettier-ignore
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    str
  );
}

export const getCommaSeparateSection = (data = [], allSectionLists) => {
  const allSectionList = [];
  const sectionData = [];
  data.forEach((section) => {
    sectionData.push(section["display-name"]);
    allSectionList.push(getParentSectionData(allSectionLists, section["parent-id"]));
  });
  return sectionData.toString() + ", " + allSectionList.toString();
};

export const getCommaSeparateAuthor = (data) => {
  return data && data.map((element) => element.name).join(",");
};

// Find parent section object from sub section
export const getParentSectionData = (sectionList, subSectionParentId) => {
  if (subSectionParentId === null) return null;
  const parentSection = sectionList && sectionList.find(({ id }) => id === subSectionParentId);
  return parentSection?.name;
};

export const fireVirtualPageOnSPA = (sections = "", authors = "", path = "", title = "") => {
  window &&
    window.pSUPERFLY &&
    window.pSUPERFLY.virtualPage({
      sections: sections,
      authors: authors,
      path: path,
      title: title,
    });
};

// Process the required response in appropriate format

export const processComments = (data) => {
  const list = [];

  data.forEach((item) => {
    const headline = get(item, ["attributes", "page", "headline"], null);
    const statusOfComment = get(item, ["attributes", "status"], "");

    if (headline) {
      const optionList = get(item, ["attributes", "body", "ops"], []);
      const url = get(item, ["attributes", "page", "url"], "");
      const commentList = [];

      optionList.forEach((option) => {
        if (typeof option.insert === "string") {
          commentList.push({
            message: option.insert,
            isMentioned: false,
          });
        } else {
          if (option.insert && option.insert.mentions) {
            commentList.push({
              link: get(option, ["attributes", "link"], ""),
              isMentioned: true,
            });
          }
        }
      });

      list.push({
        comments: commentList,
        createdAt: item.attributes.created_at,
        headline,
        url,
        statusOfComment,
      });
    }
  });

  return list;
};

export const appDownloadLinks = (lang, platform, domainSlug) => {
  if (lang === "en") {
    return platform === "google" ? ENG_GOOGLE_PLAYSTORE : ENG_APPLE_PLAYSTORE;
  } else {
    return platform === "google" ? BN_GOOGLE_PLAYSTORE : BN_APPLE_PLAYSTORE;
  }
};

export const getCurrentSite = (currentPath) => {
  if (currentPath) {
    if (currentPath.includes("epaper") || currentPath.includes("epbeta.prothom-alo.com")) {
      return "epaper";
    } else if (
      currentPath.includes("www.prothomalo.com") ||
      currentPath.includes("beta.prothomalo.com") ||
      currentPath.includes("bn.prothom-alo.com")
    ) {
      return "bn";
    } else if (currentPath.includes("kia") || currentPath.includes("kishoralo")) {
      return "kishoralo";
    } else if (currentPath.includes("bs") || currentPath.includes("bondhushava")) {
      return "bondhushava";
    } else if (currentPath.includes("pc") || currentPath.includes("protichinta")) {
      return "protichinta";
    } else if (currentPath.includes("bc") || currentPath.includes("bigganchinta")) {
      return "bigganchinta";
    } else if (currentPath.includes("trust")) {
      return "trust";
    } else if (currentPath.includes("nagorik")) {
      return "nagorik-sangbad";
    } else if (
      currentPath.includes("eng.prothom-alo.com") ||
      currentPath.includes("en-beta.prothomalo.com") ||
      currentPath.includes("en.prothomalo.com")
    ) {
      return "en";
    } else {
      return "bn";
    }
  } else return "bn";
};

export const getHeaderUrl = (currentPath) => {
  const params = parseUrl(currentPath);
  const redirectUrl = get(params, ["query", "redirect-url"], "https://www.prothomalo.com");
  const getRedirectUrl = new URL(redirectUrl);
  return getRedirectUrl.origin;
};

export const fontVariation = (type, elements, customGalleryElements, lang) => {
  const processFontStyle = (type, element) => {
    const proElement = getComputedStyle(element);
    return (element.style.fontSize =
      type === "add" ? `${parseFloat(proElement.fontSize) + 2}px` : `${parseFloat(proElement.fontSize) - 2}px`);
  };

  const computeStyles = (elements) => {
    Array.from(elements).forEach((element) => processFontStyle(type, element));
  };
  computeStyles(elements);
  computeStyles(customGalleryElements);
};

export const getDomainHostUrl = (domainSlug, domains) => {
  if (domainSlug && domains.length) {
    const domainObj = domains.find((item) => item.slug === domainSlug);

    return domainObj && domainObj["host-url"] ? domainObj["host-url"] : "";
  }

  return "";
};

export const domainSlugFind = (sections = [], section) => {
  const { "domain-slug": domainSlug = null } = sections.find(({ id }) => id === section?.id);
  return domainSlug;
};

export const checkRender = (pages = [], pageType, domainSlugs = [], domainSlug) => {
  return (
    global?.window?.location?.host?.includes("auth") || pages.includes(pageType) || domainSlugs.includes(domainSlug)
  );
};

export const currentMeta = (allSections, currentSectionId) => {
  for (let i = 0; i < allSections.length; i++) {
    if (parseInt(allSections[i]["owner-id"]) === parseInt(currentSectionId)) {
      return allSections[i].data;
    }
  }
};

export const generateDIstrictNewsSlug = (districtSlug, divisionSlug, subDistrictSlug) => {
  if (subDistrictSlug) {
    return `/${divisionSlug}/${districtSlug}/${subDistrictSlug}`;
  } else if (districtSlug) {
    return `/${divisionSlug}/${districtSlug}`;
  } else if (divisionSlug) {
    return `/${divisionSlug}`;
  } else return "";
};

export const getBirthDate = (dateStr, type = "dd MMM, yyyy") => {
  if (!dateStr) return null;

  let parsedDate;
  const formats = ["dd, MM, yyyy", "dd,MM,yyyy", "dd-MM-yyyy", "dd/MM/yyyy", "dd MMM, yyyy", "d-M-yyyy", "d/M/yyyy"];

  for (const formatStr of formats) {
    parsedDate = parse(dateStr, formatStr, new Date());
    if (isValid(parsedDate)) {
      return format(parsedDate, type);
    }
  }

  return null;
};

export const trimLastSlash = (pageUrl) => {
  return pageUrl[pageUrl.length - 1] === "/" ? pageUrl.slice(0, -1) : pageUrl;
};

export const completeUrlSetter = (menuList, sections) => {
  menuList.children.forEach((item) => {
    switch (item["item-type"]) {
      case "tag":
        item.completeUrl = item["tag-slug"] ? `/topic/${item["tag-slug"]}` : "/#";
        break;
      case "entity":
        item.completeUrl = item["entity-slug"] ? `/topic/${item["entity-slug"]}` : "/#";
        break;
      case "link":
        item.completeUrl = get(item, ["data", "link"]) || "/#";
        item.isExternalLink = true;
        break;
      case "section":
        item.completeUrl = findCompleteUrl(item, sections);
        break;
      default:
        item.completeUrl = "/#";
        break;
    }
  });
  return menuList;
};

const findCompleteUrl = (menutItem, sections) => {
  const sectionObject = find(sections, function (item) {
    return item.id === menutItem["item-id"];
  });
  if (!sectionObject) {
    return "/#";
  }
  return sectionObject["section-url"];
};

export const getMember = (member) => {
  return !member["avatar-s3-key"] && !member["avatar-url"]
    ? {
        ...member,
        "avatar-url":
          "https://media.prothomalo.com/prothomalo-bangla/2020-10/c679f2ab-c844-48c8-84a3-182f1152630c/member_avatar.png",
      }
    : member;
};

export const getContinueToText = (currentPath) => {
  if (currentPath) {
    if (currentPath.includes("kia") || currentPath.includes("kishoralo")) {
      return "Kishor alo";
    } else if (currentPath.includes("bs") || currentPath.includes("bondhushava")) {
      return "Bondhushava";
    } else if (currentPath.includes("pc") || currentPath.includes("protichinta")) {
      return "Protichinta";
    } else if (currentPath.includes("bc") || currentPath.includes("bigganchinta")) {
      return "Bigganchinta";
    } else if (currentPath.includes("trust")) {
      return "Trust";
    } else if (currentPath.includes("nagorik")) {
      return "Nagorik Sangbad";
    } else if (currentPath.includes("en-beta.prothomalo.com") || currentPath.includes("en.prothomalo.com")) {
      return "Prothom Alo English";
    } else if (
      currentPath.includes("epbeta.prothom-alo.com") ||
      currentPath.includes("epaper.prothomalo.com") ||
      currentPath.includes("epaperbeta.prothomalo.com")
    ) {
      return "Prothom Alo Epaper";
    } else {
      return "Prothom Alo";
    }
  } else return "Prothom Alo";
};

export function stripMillisecondsFromTime(date, timezone) {
  const toReturn = date.toJSON();
  if (!toReturn) {
    return toReturn;
  }
  const zonedTime = timezone && utcToZonedTime(date, timezone);
  const formatZonedTime = zonedTime && format(zonedTime, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: timezone });
  const formatToReturn = toReturn.split(".")[0] + "Z";
  return timezone ? formatZonedTime : formatToReturn;
}

export const cancelablePromise = (promise) => {
  let hasCanceled_ = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      hasCanceled_ ? reject({ isCanceled: true }) : resolve(val);
    });
    promise.catch((error) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      hasCanceled_ ? reject({ isCanceled: true }) : reject(error);
    });
  });
  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export const getSiteLink = (publisherAttributes) => {
  if (publisherAttributes.lang === "bn") {
    return publisherAttributes.english_sketches_host;
  } else if (publisherAttributes.lang === "en") {
    return publisherAttributes.bangla_sketches_host;
  }
};

export function checkEmpty(str = "") {
  if (!str || !str.trim().length) return false;
  else return str;
}

export const getAspectRatioFromEmbedJs = (element) => {
  if (element.type !== "jsembed" || !global?.window) return;

  // Decode the base64 string
  const decodedEmbedCode = global.window.atob(element?.["embed-js"]);

  // Use a regular expression to extract the height and width values
  const height = parseInt(decodedEmbedCode.match(/height="(\d+)"/)?.[1]);
  const width = parseInt(decodedEmbedCode.match(/width="(\d+)"/)?.[1]);
  if (width && height) return width / height;
};

export const embedType = (element) => {
  if (element.type !== "jsembed" || !global?.window) return;

  const decodedStr = global.window.atob(element?.["embed-js"]);

  switch (true) {
    case decodedStr.includes("www.youtube"):
      return "youtube-video";

    case decodedStr.includes("www.facebook") && decodedStr.includes("video.php"):
      return "facebook-video";

    case decodedStr.includes("special-quiz"):
      return "embed-quiz";

    default:
      return "others";
  }
};

export const widgetEntity = (text) => {
  if (!text) return null;
  const match = text.match(/\[[^\]]+\]/gim);
  return match.map((item) => {
    const str = item.replace(/\[|\]/, "");
    return {
      [str.slice(0, str.indexOf(":")).trim()]: str.slice(str.indexOf(":") + 1, -1).trim(),
    };
  });
};

export const widgetsMatch = (str = "", placeHolder = "") => {
  const reg = new RegExp(`${placeHolder}[^,]+`, "gim");
  return str.match(reg)?.toString()?.replace(placeHolder, "")?.trim();
};

export const numberConverter = (labelValue = 0) => {
  if (!labelValue) return;

  const absNumber = Math.abs(Number(labelValue));

  // Nine Zeroes for Billions
  return absNumber >= 1.0e9
    ? absNumber / 1.0e9 + "B"
    : // Six Zeroes for Millions
    absNumber >= 1.0e6
    ? absNumber / 1.0e6 + "M"
    : // Three Zeroes for Thousands
    absNumber >= 1.0e3
    ? absNumber / 1.0e3 + "K"
    : absNumber;
};

export const dataAttr = (key, value) => ({ [key]: value });

export function getFilterSections(sections, domainSlug = null) {
  return sections.filter(({ "domain-slug": slug }) => String(slug).includes(domainSlug));
}

export const setCookie = (name, value) => {
  const cookie = new Cookies();
  if (!cookie.get(name)) {
    cookie.set(name, value, { path: "/", maxAge: 60 * 60 * 24 * 365 });
  }
};

export const getCookie = (name) => {
  const cookie = new Cookies();
  return cookie.get(name);
};

export const removeCookie = (name) => {
  const cookie = new Cookies();
  return cookie.remove(name, { path: "/" });
};

export const hasCollectionHeader = (collection = {}) => {
  const { show_collection_name_website: showCollectionName, desktop_banner: desktopBanner = "" } =
    collection["associated-metadata"] || {};
  const coverImageS3key = collection.metadata?.["cover-image"]?.["cover-image-s3-key"];

  return showCollectionName || Boolean(desktopBanner.trim()) || Boolean(coverImageS3key);
};

export const trim = (text = "", defaultText = "") => {
  return String(text || "").trim() || defaultText;
};

export const showSpecificNumOfWord = (string, num = 6) => {
  if (!string) return;

  const strArr = string.split(" ");
  const resultedStr = strArr.slice(0, num).join(" ");

  return strArr.length > num ? `${resultedStr}...` : resultedStr;
};

export const urlToBase64 = (url) => {
  if (!window) return "";
  const encode = unescape(encodeURIComponent(url));
  return btoa(encode);
};

export const getSubjectTopicTag = (publisherAttributes, story) => {
  if (!publisherAttributes.subject_topic_linked_entity) {
    const subjectTopicTag =
      story.tags && story.tags.length > 0 ? story.tags.find((tag) => tag.type === "subject-topic-tag") : null;
    return subjectTopicTag;
  }

  const subjectTopicAttributeId = get(story, ["metadata", "story-attributes", "subjecttopic", "0", "id"], null);
  const subjectTopicLinkedEntity =
    story["linked-entities"] && story["linked-entities"].length > 0 && subjectTopicAttributeId
      ? story["linked-entities"].find((item) => item.id === subjectTopicAttributeId)
      : null;
  return subjectTopicLinkedEntity;
};

export const getPathName = (url = "") => {
  if (!url) return "";
  return url.split("?")?.[0] || "";
};

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export const getQuestionPrefix = (story, lang, domainSlug) => {
  if (!story) return null;

  const date = new Date(story["updated-at"] || story["last-published-at"]);
  if (!isValidDate(date)) return null;

  const checkDate = new Date(2023, 11, 1);
  if (date < checkDate) {
    return lang === "en" ? "Q" : "প্রশ্ন";
  }

  const { brand_name: brandName = "" } = SOCIAL_TAG[domainSlug || lang] || STATIC_TAGS;
  return brandName;
};

export const getStoriesFromCollection = (collection, limit) => {
  if (!(collection && collection.items && collection.items.length)) return [];
  const stories = [];
  for (const item of collection.items) {
    const { type, story } = item;
    type === "story" && story && stories.push(story);
  }

  return stories.slice(0, limit);
};

export function imageParams(gumletDpr) {
  if (global.isMobile === "undefined") {
    return { auto: ["format", "compress"], fmt: "webp", dpr: "1.0" };
  }

  const name = global.isMobile ? "mobile" : "desktop";
  const dpr = gumletDpr[name];
  let imgParams = { auto: ["format", "compress"], fmt: "webp" };
  if (dpr) {
    imgParams = { auto: ["format", "compress"], fmt: "webp", dpr };
  }

  return imgParams;
}

export function paramsToObject(urlParams = "") {
  if (!(urlParams && urlParams.length > 2)) return {};

  const params = new URLSearchParams(urlParams);
  const entries = params.entries();
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

export const multiAuthorSeperator = (authors, lang, index) => {
  const len = authors?.length;

  if (len === 1 || len - 1 === index) {
    return " ";
  } else if (len - 2 === index) {
    return ` ${staticTexts(lang).and} `;
  } else {
    return ", ";
  }
};

export const openCheck = (query = {}) => {
  const keys = ["published-before", "published-after", "section-id", "author-id", "type"];
  return Object.keys(query).some((item) => keys.includes(item));
};

export const getImageCropUrl = (story) => {
  const alternativeHeroImage = story.alternative?.home?.default?.["hero-image"];

  const {
    "hero-image-metadata": heroImageMetaData,
    "hero-image-s3-key": heroImage = alternativeHeroImage ? alternativeHeroImage.key : undefined,
  } = alternativeHeroImage || story;

  const imageHeightWidth = pick(heroImageMetaData, ["height", "width"], {});
  const focusPoint = pick(heroImageMetaData, ["focus-point"]);
  const ratio = [1, 1];
  const bounds = imageBounds([imageHeightWidth.width, imageHeightWidth.height], ratio, focusPoint["focus-point"]);

  return `${heroImage}?rect=${bounds.join()}&auto=format,compress&fmt=webp&dpr=1.0&format=webp&w=120`;
};

function imageBounds(imageDimensions = [750, 422], aspectRatio = [16, 9], focusPoint = [125, 95]) {
  let expectedHeight, expectedWidth, bound;

  if (imageDimensions[0] * aspectRatio[1] < imageDimensions[1] * aspectRatio[0]) {
    expectedHeight = (imageDimensions[0] * aspectRatio[1]) / aspectRatio[0]; //
    bound = findBounds(imageDimensions[1], expectedHeight, focusPoint[1]);
    return [0, Math.round(bound), imageDimensions[0], Math.round(expectedHeight)];
  } else {
    expectedWidth = (imageDimensions[1] * aspectRatio[0]) / aspectRatio[1];
    bound = findBounds(imageDimensions[0], expectedWidth, focusPoint[0]);
    return [Math.round(bound), 0, Math.round(expectedWidth), imageDimensions[1]];
  }
}

function findBounds(imageWidth, cropWidth, focusPoint) {
  const leftBound = findLeftBound(imageWidth, cropWidth / 2, focusPoint);
  if (leftBound + cropWidth > imageWidth) {
    return imageWidth - cropWidth;
  } else {
    return leftBound;
  }
}

function findLeftBound(imageWidth, halfCropWidth, focusPoint) {
  if (focusPoint - halfCropWidth < 0) {
    return 0;
  } else if (focusPoint + halfCropWidth > imageWidth) {
    return imageWidth - halfCropWidth;
  } else {
    return focusPoint - halfCropWidth;
  }
}

export const createQueryStringFromObj = (params) => {
  return Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join("&");
};

export function brokenStoryUrl(url = "") {
  const regex = /([\d\u09E6-\u09EF]+%)/g;

  if (url.match(regex)) {
    return url.replace(regex, "$125");
  }

  return null;
}

export function isUrlEncoded(url) {
  const regex = /%[0-9A-Fa-f]{2}/;
  return regex.test(url);
}

export const calcWidthsWithDpr = (widths, dpr) => {
  if (!dpr || dpr === 1) return widths;

  return widths.map((width) => Math.round(width * dpr));
};
